import { makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  IOverviewFetch,
  OverviewCellDTO,
  OverviewCellDriverDTO,
  OverviewEmsDTO,
  OverviewMetricsDTO,
  OverviewPlantDTO,
  EnergyMeterKWhDTO
} from 'src/dto/overview.dto';
import {
  IStatistic,
  IOverviewEms,
  IOverviewCellDriver,
  IWeather,
  IChart,
  IOverviewCell,
  IOverviewPlant,
  IAlarmChartData,
  IChartPercent,
  IChartPlantKw,
  IMetricsPlant
} from 'src/interfaces/overview';
import { IHttpService } from 'src/services/http.service';
import moment from 'moment';

export interface IEnergyMeterStore {
  listChartEMKWhByDay?: IChartPlantKw[];
}

export interface IOverviewStore {
  fetchEms(request?: IOverviewFetch): Promise<void>;
  fetchCellDriver(request?: IOverviewFetch): Promise<void>;
  fetchCellDriverChartData(request?: IOverviewFetch): Promise<void>;
  // fetchCell(request?: IOverviewFetch): Promise<void>;
  fetchPlant(request?: IOverviewFetch): Promise<void>;
  updateStatusOverViewEms(status: 'start' | 'stop'): void;
  overviewEms?: IStatistic;
  overviewPlant?: IOverviewPlant;
  overviewCellDriver?: IOverviewCellDriver;
  overviewCell?: IOverviewCell;
  weather?: IWeather;
  listChart: IChart[];
  dispose(): void;
  listChartAlarm?: IAlarmChartData[];
  listChartPercent: IChartPercent[];
  listChartPlantKwKwh: IChartPlantKw[];
}

export class OverviewStore implements IOverviewStore, IEnergyMeterStore {
  overviewEms?: IStatistic;
  overviewCellDriver?: IOverviewCellDriver;
  overviewCell?: IOverviewCell;
  weather?: IWeather;
  listChart: IChart[] = [];
  listChartPercent: IChartPercent[] = [];
  listChartPlantKwKwh: IChartPlantKw[] = [];
  listChartAlarm?: IAlarmChartData[];
  listChartEMKWhByDay?: IChartPlantKw[] = [];
  overviewPlant?: IOverviewPlant;
  metricsPlant?: IMetricsPlant;
  statusOverview?: 'start' | 'stop';
  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      overviewEms: observable.ref,
      overviewCellDriver: observable.ref,
      overviewCell: observable.ref,
      overviewPlant: observable.ref,
      metricsPlant: observable.ref,
      weather: observable.ref,
      listChart: observable.ref,
      listChartAlarm: observable.ref,
      statusOverview: observable.ref,
      listChartPercent: observable.ref,
      listChartEMKWhByDay: observable.ref
    });
  }
  public async fetchEms(request?: IOverviewFetch) {
    const overviewEmsDto = new OverviewEmsDTO(request);
    const res: ResponseDTO<IOverviewEms> = await this.http.request<
      OverviewEmsDTO,
      IOverviewEms
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.dispose();
        this.listChartAlarm = res.data?.alarms_charts || [];
        this.overviewEms = res.data?.ems_statistic;
        this.weather = res.data?.weather;
        this.listChart = res.data?.charts as IChart[];
        this.statusOverview = res.data?.status;
      });
    }
  }

  public async fetchCellDriver(request?: IOverviewFetch) {
    const overviewEmsDto = new OverviewCellDriverDTO(request);
    const res: ResponseDTO<IOverviewCellDriver> = await this.http.request<
      OverviewCellDriverDTO,
      IOverviewCellDriver
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.dispose();
        this.overviewCellDriver = res.data;
        this.listChart = res.data?.charts || [];
      });
    }
  }

  public async fetchCellDriverChartData(request?: IOverviewFetch) {
    const overviewEmsDto = new OverviewCellDriverDTO(request);
    const res: ResponseDTO<IOverviewCellDriver> = await this.http.request<
      OverviewCellDriverDTO,
      IOverviewCellDriver
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listChart = res.data?.charts || [];
      });
    }
  }

  public async fetchCell(request?: IOverviewFetch) {
    const overviewEmsDto = new OverviewCellDTO(request);
    const res: ResponseDTO<IOverviewCell> = await this.http.request<
      OverviewCellDTO,
      IOverviewCell
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.dispose();
        this.overviewCell = res.data;
        this.listChart = res.data?.charts || [];
      });
    }
  }

  public async fetchPlantLoadMonitor(request?: IOverviewFetch): Promise<void> {

    if (request?.type && request.type == 'date_range' &&
          request.from && request.to)
    {
      let newFrom = request.from;
      let tempDate = new Date(request.from);
      let newTo = moment(new Date(tempDate.setDate(tempDate.getDate() + 1))).toISOString();

      let tmpOverviewPlant: IOverviewPlant | undefined;
      let tmpListChartAlarm: IAlarmChartData[] | undefined = [];
      let tmpListChartPlantKwKwh: IChartPlantKw[] | undefined = [];
      let tmpListChartPercent: IChartPercent[] | undefined = [];
      let finished = false;
      const requests = [];
     
      do
      {
        const newRequest = {
          ...request,
          from: newFrom,
          to: newTo
        }        

        const overviewEmsDto = new OverviewPlantDTO(newRequest);
        requests.push(this.http.request<
            OverviewPlantDTO,
            IOverviewPlant
          >(overviewEmsDto));

        if (newTo == request.to)
        {
          finished = true;
        }

        newFrom = newTo;
        newTo = moment(new Date(tempDate.setDate(tempDate.getDate() + 1))).toISOString();

        if (new Date(newTo) > new Date(request.to))
        {
          newTo = request.to;
        }

        tempDate = new Date(newTo);

      } while (!finished);
      
      await Promise.all(requests)
      .then( responses => {
        
        for (const res of responses)
        {
          if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS)
          { 
            tmpOverviewPlant = res.data;
            tmpListChartAlarm = tmpListChartAlarm?.concat(res.data?.alarm_chart || []);
            tmpListChartPlantKwKwh = tmpListChartPlantKwKwh?.concat(res.data?.chartkWh || []);
            tmpListChartPercent = tmpListChartPercent?.concat(res.data?.chartPercent || []);          
          }
          else
          {
            console.error(`Error Response: Details ${JSON.stringify(res)}`)
          }
        }
      });
      //console.log('End of Data Requests', moment().format('yyyy-MM-DD HH:mm:ss'))
      runInAction(() => {
        this.dispose();
        this.overviewPlant = tmpOverviewPlant;
        this.listChartAlarm = tmpListChartAlarm;
        this.listChartPlantKwKwh = tmpListChartPlantKwKwh || [];
        this.listChartPercent = tmpListChartPercent || [];
      });      
    }
    else
    {
      const overviewEmsDto = new OverviewPlantDTO(request);
      const res: ResponseDTO<IOverviewPlant> = await this.http.request<
        OverviewPlantDTO,
        IOverviewPlant
      >(overviewEmsDto);
      if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
        runInAction(() => {
          this.dispose();
          this.overviewPlant = res.data;
          this.listChartAlarm = res.data?.alarm_chart
          this.listChartPlantKwKwh = res.data?.chartkWh || [];
          this.listChartPercent = res.data?.chartPercent || []
        });
      }
      else{
        throw Error('Load Monitor: fetch Plant failed')
      }
    }    

  }

  public async fetchEnergyMeterKWh(request: IOverviewFetch): Promise<void> {

    if ( request.type == 'date_range' && request.from && request.to )
    {
      const energyMeterKWhDto = new EnergyMeterKWhDTO(request);
      const res: ResponseDTO<IOverviewPlant> = await this.http.request<
        OverviewPlantDTO,
        IOverviewPlant
      >(energyMeterKWhDto);

      if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS)
      {
        runInAction(() => {
          this.listChartEMKWhByDay = [];

          //this.overviewPlant = res.data;
          this.listChartEMKWhByDay = res.data?.chartkWh || [];
        });
      }
    }
  }

  public async fetchPlant(request?: IOverviewFetch): Promise<void> {
    const overviewEmsDto = new OverviewPlantDTO(request);
    const res: ResponseDTO<IOverviewPlant> = await this.http.request<
      OverviewPlantDTO,
      IOverviewPlant
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.dispose();
        this.overviewPlant = res.data;
        this.listChartAlarm = res.data?.alarm_chart
        this.listChartPlantKwKwh = res.data?.chartkWh || [];
        this.listChartPercent = res.data?.chartPercent || []
      });
    }
    else{
      throw Error('fetch Plant failed')
    }

  }

  public async fetchMetrics(request?: IOverviewFetch): Promise<void> {
    const overviewEmsDto = new OverviewMetricsDTO(request);
    const res: ResponseDTO<IMetricsPlant> = await this.http.request<
    OverviewMetricsDTO,
      IMetricsPlant
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.metricsPlant = res.data;
      });
    }
    else{
      throw Error('fetch Metrics failed')
    }

  }

  public async fetchPlantIP(request?: IOverviewFetch): Promise<void> {
    const overviewEmsDto = new OverviewPlantDTO(request);
    const res: ResponseDTO<IOverviewPlant> = await this.http.request<
      OverviewPlantDTO,
      IOverviewPlant
    >(overviewEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.dispose();
        this.overviewPlant = res.data;
      });
    }
    else{
      throw Error('fetch Plant failed')
    }

  }
  public updateStatusOverViewEms(status: 'stop' | 'start') {
    runInAction(() => {
      this.statusOverview = status;
    });
  }

  public dispose(): void {
    this.overviewEms = undefined;
    this.overviewPlant = undefined;
    this.overviewCellDriver = undefined;
    this.overviewCell = undefined;
    this.weather = undefined;
    this.listChart = [];
    this.listChartAlarm = [];
    this.listChartPercent =[];
    this.listChartPlantKwKwh = [];
  }
}
